<template>
    <div class="page">
        <dv-loading class="pageLoading" v-if="loading">Loading...</dv-loading>
        <div class="header">
            <img class="bj" src="../assets/image/header.png">
            <div class="container">
                <div class="left">
                    <!-- <div :class="['icon_tq', 'tq-'+weather.wea_img]"></div> -->
                    <div class="des">
                        <div class="t">巨烨健康管理云平台</div>
                        <div class="t1">ZAYATA HEALTH SAAS PLATFROM</div>
                    </div>
                </div>
                <div class="middle">
                    <div class="t"  @click="$router.back()" style="cursor: pointer;">药品信息化追溯区块链大屏</div>
                    <div class="t1">BLOCKCHAIN SCREEN FOR DRUG INFORMATIZATION TRACEABILITY</div>
                </div>
                <div class="right">
                    <div class="des">
                        <div class="t">{{timeStr}}</div>
                        <div class="t1">{{timeStr1}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="leftContainer">
                <div class="top">
                    <div class="liContainer">
                        <div class="li">
                            <div class="item">
                                <div>国家药监局</div>
                                <div>药品追溯监管系统</div>
                            </div>
                            <div class="item">
                                <div>国家级</div>
                                <div>追溯监管系统</div>
                            </div>
                            <div class="item">
                                <img class="jt" src="../assets/image/jt.png" alt="">
                            </div>
                            <div class="item">
                                <div>省级</div>
                                <div>追溯监管系统</div>
                            </div>
                        </div>
                    </div>
                    <div class="liContainer">
                        <div class="li">
                            <div class="item">
                                <div>药品追溯</div>
                                <div>协同服务平台</div>
                            </div>
                            <div class="item">
                                <div>追溯</div>
                                <div>协同模块</div>
                            </div>
                            <div class="item">
                                <img class="jt" src="../assets/image/jt.png" alt="">
                            </div>
                            <div class="item">
                                <div>监管</div>
                                <div>协同模块</div>
                            </div>
                        </div>
                    </div>
                    <div class="liContainer">
                        <div class="li">
                            <div class="item">
                                <div>国家认证</div>
                                <div>药品追溯系统</div>
                            </div>
                            <div class="item">
                                <div>巨烨健康管理云平台</div>
                                <div>智能健康设备溯源上报系统</div>
                            </div>
                        </div>
                    </div>
                    <img class="jtmax" src="../assets/image/jtmax.png" alt="">
                </div>
                <div class="middle">
                    <div class="item">
                        <div class="tag">标 准</div>
                        <div class="rightContainer">
                            <div class="title">药监局药品信息化追溯体系标准要求</div>
                            <div class="text">帮助医疗健康智能硬件企业快速接入符合药监局药品追溯体系技术安全标准、数据集标准、数据交互标准的云上报系统</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="tag">能 力</div>
                        <div class="rightContainer">
                            <div class="title">行业互联互通能力</div>
                            <div class="text">实现下游商业/终端可上报、验证，合法获取下游流向数据，对接上游合规机构及国家药品监管部门，实现药品信息化全链路管控</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="tag">规 范</div>
                        <div class="rightContainer">
                            <div class="title">规范智能健康设备溯源上报操作</div>
                            <div class="text">指导医疗健康智能硬件企业快速接入云上报系统，上报符合药监局数据交互标准的用药数据，快速通过国家认证追溯系统接入认证</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="tag">安 全</div>
                        <div class="rightContainer">
                            <div class="title">成熟医疗区块链身份管理技术</div>
                            <div class="text">联盟链根据业务需求提供相应的认证和身份管理机制，支持建立身份管理的策略, 支持利用具体身份认证方法支撑身份管理策略</div>
                        </div>
                    </div>
                </div>
                <div class="cardContainer">
                    <div class="title">上链记录数量趋势</div>
                    <div id="chart" ref="chart3" style="margin-top:0.1rem"></div>
                </div>
            </div>
            <div class="middleContainer">
                <div class="chartContainer">
                    <div id="chart" ref="chart"></div>
                </div>
                <div class="cardList">
                    <div class="card">
                        <img src="../assets/image/card_1.png" alt="">
                        <div class="t">覆盖区域</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_02.province_count.number" :interval="500"></DigitalTransform>
                            <div>{{stat_02.province_count.word}}</div>
                            <sub>省</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#1DC9B7']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/card_2.png" alt="">
                        <div class="t">生态伙伴</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_02.partner_count.number" :interval="500"></DigitalTransform>
                            <div>{{stat_02.partner_count.word}}</div>
                            <sub>个</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#FFB822']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/card_3.png" alt="">
                        <div class="t">可溯源设备</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_02.devices_count.number" :interval="500"></DigitalTransform>
                            <div>{{stat_02.devices_count.word}}</div>
                            <sub>台</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#D74CFF']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/card_4.png" alt="">
                        <div class="t">上报数据</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_02.report_count.number" :interval="500"></DigitalTransform>
                            <div>{{stat_02.report_count.word}}</div>
                            <sub>次</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#ECE156']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                </div>
                <div class="mapContainer">
                </div>
                <div class="content">
                    <div class="cardContainer">
                        <div class="title">实时上链情况</div>
                        <dv-scroll-board v-if="config.data.length>0" :key="key" :config="config" class="dv-scroll-board" />
                    </div>
                </div>
            </div>
            <div class="rightContainer">
                <div class="cardContainer card1" style="padding-bottom:0">
                    <div class="title">溯源主要企业设备比例</div>
                    <div id="chart" ref="chart2" style="margin-top:0.1rem"></div>
                </div>
                <div class="cardContainer">
                    <div class="title">溯源上报设备总数</div>
                    <div id="chart" ref="chart1"></div>
                </div>
                <div class="cardContainer card3">
                    <div class="title">区块链节点运行情况</div>
                    <dv-scroll-board v-if="config1.data.length>0" :key="key" :config="config1" class="dv-scroll-board" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import worldData from "./json/china.json";
    echarts.registerMap("china", worldData);
    import axios from '@/axios/index'
    import DigitalTransform from "vue-digital-transform";
    import * as moment from 'moment'
    import ut from '@/comm/ut'
    import * as math from 'mathjs'
    moment.locale("zh-cn")
    export default {
        components: {
            DigitalTransform
        },
        data() {
            return {
                actShowLableIndex: 0,
                timeStr: '',
                timeStr1: '', //星期几
                key: new Date().getTime(),
                loading: true,
                visualMapMax: 0,
                stat_01: [],
                stat_02: { province_count: { number: 0, word: "" }, partner_count: { number: 0, word: "" }, devices_count: { number: 0, word: "" }, report_count: { number: 0, word: "" } },
                stat_03: [],
                stat_04: [],
                stat_05: [],
                stat_06: [],
                stat_07: [],
                refreshTimer: null,
                config: {
                    headerBGC: "rgba(29, 201, 183, 0.2)",
                    oddRowBGC: "rgba(29, 201, 183, 0.1)", //偶数行背景色
                    evenRowBGC: "rgba(29, 201, 183, 0)", //	奇数行背景色	
                    rowNum: 3,
                    header: ['区块ID', '区块高度', '创建时间', '记录数量'],
                    data: [],
                    // oddRowBGC: "transparent",
                    // evenRowBGC: " transparent;",
                    // hoverPause: true,
                    // waitTime: 1000,
                    // columnWidth: [110],
                    align: ["left", "center", "center", "center"]
                },
                config1: {
                    headerBGC: "rgba(194, 22, 244, 0.3)",
                    oddRowBGC: "rgba(194, 22, 244, 0.2)", //偶数行背景色
                    evenRowBGC: "rgba(29, 201, 183, 0)", //	奇数行背景色	
                    rowNum: 3,
                    header: ['节点', '核心数', 'CPU使用率', '内存占用率'],
                    data: [],
                    // oddRowBGC: "transparent",
                    // evenRowBGC: " transparent;",
                    // hoverPause: true,
                    // waitTime: 1000,
                    // columnWidth: [110],
                    align: ["center", "center", "center", "center"]
                }
            }
        },
        watch: {
            '$store.state.onresizeNumber': {
                handler() {
                    this.key = new Date().getTime()
                    if (this.chart) this.$nextTick(this.chart.resize) //解决页面变化导致布局的问题
                    if (this.chart1) {
                        this.chart1.clear()
                        this.setChart1()
                        this.chart1.resize();
                    }
                    if (this.chart2) {
                        this.chart2.clear()
                        this.setChart2()
                        this.chart2.resize();
                    }
                    if (this.chart3) {
                        this.chart3.clear()
                        this.setChart3()
                        this.chart3.resize();
                    }
                }
            }
        },
        async created() {
            await this.getData()
            this.setTime()
            this.loading = false
        },
        methods: {
            setConfigData() { //实时上链情况
                let configData = []
                for (let i = 0; i < this.stat_03.length; i++) {
                    const item = this.stat_03[i];
                    configData.push([item.blockId, item.height, item.createTime, item.numberOfTransactions])
                }
                this.config.data = configData
            },
            setConfigData1() { //区块链节点运行情况
                let configData = []
                for (let i = 0; i < this.stat_06.length; i++) {
                    const item = this.stat_06[i];
                    let cpuUsage = math.chain(math.bignumber(item.cpuUsage)).multiply(100) + "%"
                    let memoryUtilization = math.chain(math.bignumber(item.memoryUtilization)).multiply(100) + "%"
                    configData.push([item.nodeSN, item.coreAmount, cpuUsage, memoryUtilization])
                }
                this.config1.data = configData
            },

            setTime() { //设置时间
                moment.locale("zh-cn")
                this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                moment.locale("en")
                this.timeStr1 = `${ moment().format('LL')}`
                if (setIntervalTime) clearInterval(setIntervalTime)
                let setIntervalTime = setInterval(() => {
                    moment.locale("zh-cn")
                    this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                    moment.locale("en")
                    this.timeStr1 = `${ moment().format('LL')}`
                }, 1000);
            },
            async getData() { //请求数据 设置数据
                let response = await axios.request({
                    url: "/api/dataworks.super/trace_data",
                    method: "GET",
                })
                // stat_01: "上链记录数量趋势"
                // stat_02: "统计数据"
                // stat_03: "实时上链情况"
                // stat_04: "溯源主要企业设备比例"
                // stat_05: "溯源上报设备总数"
                // stat_06: "区块链节点运行情况"
                let { stat_01 = [], stat_02 = {}, stat_03 = [], stat_04 = [], stat_05 = [], stat_06 = [], stat_07 = [], refresh = 300 } = response.data.data
                if (this.refreshTimer) clearInterval(this.refreshTimer)
                this.refreshTimer = setInterval(this.getData, refresh * 1000);
                let visualMapMax = 0
                stat_07.forEach(item => {
                    item.value = parseFloat(item.value)
                    item.gps = [parseFloat(item.gps[0]), parseFloat(item.gps[1])]
                    if (item.value > visualMapMax) visualMapMax = item.value
                })
                this.visualMapMax = visualMapMax
                // stat_05.sort((item, item1) => item.month - item1.month)
                // stat_06.sort((item, item1) => item.month - item1.month)
                for (const key in stat_02) stat_02[key] = ut.DivideTextAndNumbers(stat_02[key])
                stat_03.forEach(item => {
                    item.createTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                })
                this.stat_01 = stat_01
                this.stat_02 = stat_02
                this.stat_03 = stat_03
                this.stat_04 = stat_04
                this.stat_05 = stat_05
                this.stat_06 = stat_06
                this.stat_07 = stat_07
                this.setChart()
                this.setChart1()
                this.setChart2()
                this.setChart3()
                this.setConfigData()
                this.setConfigData1()
            },
            setChart() {
                let rldData = [] // 热力点数据
                let stat_07 = this.stat_07
                let endData = []
                stat_07.forEach((item, index) => {
                    rldData.push({
                        name: item.city,
                        value: [item.gps[0], item.gps[1], item.value]
                    })
                    endData.push({
                        label: {
                            show: index === 0 ? true : false
                        },
                        name: item.city,
                        value: [item.gps[0], item.gps[1], item.value]
                    })
                })

                let series = [
                    { //热力点
                        zlevel: 999,
                        type: 'heatmap',
                        coordinateSystem: 'geo',
                        data: rldData,
                    },
                    {
                        zlevel: 99,
                        type: 'scatter', //层级比地图区高 用来触摸显示自定义tooltip
                        coordinateSystem: 'geo', //
                        symbolSize: 50,
                        itemStyle: {
                            opacity: 0,
                        },
                        data: rldData,
                        tooltip: {
                            borderColor: "",
                            borderWidth: 0,
                            backgroundColor: "",
                            padding: 0,
                            formatter: function(params, ticket, callback) {
                                if (params.seriesType == "scatter") {
                                    return `<div style="border: 1px solid #C216F4;height:32px;text-align: center;display:flex;font-size: 16px;color:#FFFFFF;font-weight: 600;width:auto">
                                            <div style="background: rgba(194, 22, 244, 0.4);line-height: 32px;padding:0 17px;border-right: 1px solid #C216F4;">${params.name}</div>
                                            <div style="line-height: 32px;padding:0 17px;background: rgba(194, 22, 244, 0.3);">${params.value[params.value.length-1]}</div>
                                        </div>`
                                }
                            }
                        }

                },
                    { // 结束点
                        visualMap: false,
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 1,
                        rippleEffect: {
                            brushType: 'stroke'
                        },
                        symbolSize: 6,
                        itemStyle: {
                            normal: {
                                opacity: 1,
                                color: 'rgba(49, 255, 233, 1)'
                            }
                        },
                        data: endData,
                        label: {
                            show: true,
                            color: "#FFFFFF",
                            opacity: 1,
                            position: "right",
                            offset: [0, 0],
                            formatter: function(params) {
                                return `{line|——}{name|${params.data.name}}{value|${params.data.value[params.data.value.length-1]}}`
                                // return `{name|${params.name}{value|{d}%}`,
                            },
                            rich: {
                                line: {
                                    color: "#FFFFFF"
                                },
                                name: {
                                    backgroundColor: 'rgba(194, 22, 244, 0.6)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                },
                                value: {
                                    backgroundColor: 'rgba(194, 22, 244,0.3)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                }
                            }
                        }
                }
            ];

                this.chart = echarts.init(this.$refs.chart);
                this.chart.setOption({
                    visualMap: {
                        show: false,
                        orient: "horizontal",
                        zlevel: 999,
                        type: "continuous", //类型为分段型
                        seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的  默认取所有系列 不指定会覆盖所有颜色
                        min: 0,
                        max: this.visualMapMax,
                        splitNumber: 5, //分5段
                        inRange: {
                            color: ['rgba(49, 255, 233, 1)', 'rgba(49, 255, 233, 0.9)', 'rgba(49, 255, 233, 0.8)', 'rgba(49, 255, 233, 0.7)', 'rgba(49, 255, 233, 0.6)'] //每段的颜色
                        }
                    },
                    tooltip: {
                        show: true
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        // left: '0%',
                        // right: '0%',
                        // bottom: '0%',
                        // top:'10%',
                        containLabel: true
                    },
                    // layoutCenter: ['70%', '50%'], //位置
                    // layoutSize: '65%', //大小
                    geo: {
                        top: '20%',
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params, ticket, callback) { return }
                        },
                        name: '世界地图',
                        type: 'map',
                        map: 'china',
                        label: {
                            emphasis: {
                                show: true,
                                color: '#fff'
                            }
                        },
                        zoom: 1.2,
                        // roam: true, //支持拖拽缩放
                        // scaleLimit: { //滚轮缩放的极限控制
                        //     min: 0.5, //缩放最小大小
                        //     max: 6, //缩放最大大小
                        // },
                        itemStyle: {
                            normal: {
                                borderWidth: 1, //边际线大小
                                // borderColor: 'red', //边界线颜色
                                areaColor: '#3d6fd9', //默认区域颜色
                                borderColor: '#719dfa'
                            },
                            emphasis: {
                                show: true,
                                // areaColor: '#2a333d'
                                areaColor: '#3066ba', //鼠标滑过区域颜色
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        }
                    },
                    series
                })
                let options = this.chart.getOption();
                if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
                this.mapLableShowTimer = setInterval(() => {
                    this.actShowLableIndex = this.actShowLableIndex === options.series[2].data.length - 1 ? 0 : this.actShowLableIndex + 1
                    options.series[2].data.forEach((item, index) => {
                        item.label.show = index === this.actShowLableIndex ? true : false
                    })
                    this.chart.setOption(options, false);
                }, 3000);
            },
            setChart1() { //溯源上报设备总数
                this.chart1 = echarts.init(this.$refs.chart1);
                let deviceAmountData = []
                let dateData = []
                for (let i = 0; i < this.stat_05.length; i++) {
                    const item = this.stat_05[i];
                    deviceAmountData.push(item.deviceAmount)
                    dateData.push(`${item.year}\n${item.month}月`)
                }

                let series = [{
                        name: '溯源上报设备总数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: deviceAmountData
                    }
                ]
                this.chart1.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(47, 231, 211, 0.8)"],
                    grid: {
                        top: '20px',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },

                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            fontSize: ut.fontSize(11),
                            color: "rgba(255, 255, 255, 0.6)"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        },

                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            },
            setChart2() { //溯源主要企业设备比例
                let stat_04 = this.stat_04
                let data = []
                for (let i = 0; i < stat_04.length; i++) {
                    const item = stat_04[i];
                    data.push({
                        label: {
                            show: i === 0 ? true : false
                        },
                        value: parseFloat(item.deviceAmount),
                        name: item.supplierName
                    })
                }
                this.chart2 = echarts.init(this.$refs.chart2);
                this.chart2.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        left: '0%',
                        top: '0%',
                        containLabel: true
                    },
                    legend: {
                        // padding: [5, 0, 0, 0],
                        // icon: "circle",
                        type: 'scroll',
                        pageIconColor: "rgba(255,255,255,1)",
                        pageIconInactiveColor: "rgba(255,255,255,0.5)",
                        pageTextStyle: {
                            color: "rgba(255,255,255,1)",
                            fontSize: ut.fontSize(14),
                        },
                        textStyle: {
                            fontSize: ut.fontSize(12),
                            color: "#FFFFFF"
                        }
                    },
                    color: ["rgba(194, 22, 244, 1)", 'rgba(30, 143, 255, 1)', 'rgba(29, 201, 183, 1)', 'rgba(255, 184, 34, 1)', 'rgba(236, 225, 86, 1)'],
                    series: [
                        {
                            name: '溯源主要企业设备比例',
                            type: 'pie',
                            animation: true,
                            radius: ['30%', '80%'],
                            top: "10%",
                            avoidLabelOverlap: true,
                            data,
                            label: {
                                show: true,
                                fontSize: ut.fontSize(14),
                                color: 'rgba(159, 189, 232, 1)',
                                formatter: params => {
                                    return `${params.data.value}\n${params.data.name}`
                                }
                            },
                        }
                    ]
                })
                // let options = this.chart2.getOption();
                // options.series[0].data[0].label = { show: false }
                // this.chart2.setOption(options, true);
                // this.chart2.dispatchAction({
                //     type:"pie",
                //     seriesIndex:0,
                //     dataIndex:1
                // })
            },
            setChart3() { //上链记录数量趋势
                this.chart3 = echarts.init(this.$refs.chart3)
                let data = []
                let seriesData1 = []
                let xAxisData = []
                let valueArry = this.stat_01.map(item => parseFloat(item.recordAmount))
                const arrayAverage = arr => arr.reduce((acc, val) => acc + val, 0) / arr.length; //求平均值函数
                let actNumber = ut.findCloseNum(valueArry, arrayAverage(valueArry)) //最接近平均值的值
                for (let i = 0; i < this.stat_01.length; i++) {
                    const item = this.stat_01[i];
                    xAxisData.push(`${item.year}\n${item.month}月`)
                    data.push({
                        value: item.recordAmount,
                    })

                    seriesData1.push({
                        value: item.recordAmount,
                        label: {
                            show: item.recordAmount == actNumber ? true : false
                        }
                    })
                }
                this.chart3.setOption({
                    grid: {
                        left: -ut.fontSize(28),
                        top: '12.5%',
                        width: '100%',
                        height: '87.5%',
                        containLabel: true
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        data: xAxisData,
                        boundaryGap: false,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: ut.fontSize(10),
                                color: 'rgba(255, 255, 255, 0.6)'
                            },
                            // formatter: (params) => {
                            //     return params
                            // }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        show: false
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '上链记录数量趋势',
                            tooltip: {
                                show: false
                            },
                            animation: false,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '上链记录数量趋势',
                            smooth: true,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData1,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(29, 201, 183, 1)',
                                    shadowBlur: 40,
                                    label: {
                                        distance: 0,
                                        show: false,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        formatter: (params) => {
                                            return `{a|${params.value}}\n{b|}`
                                        },
                                        fontSize: 16,
                                        rich: {
                                            a: {
                                                color: "#FFFFFF",
                                                verticalAlign: "middle",
                                                padding: [6, 12, 3, 12],
                                                borderRadius: 15,
                                                backgroundColor: "rgba(29, 201, 183, 1)",
                                                textShadowBlur: 0,
                                                shadowBlur: 0
                                                // backgroundColor:{
                                                //     image:"https://fastly.jsdelivr.net/gh/apache/echarts-website@asf-site/zh/images/logo.png?_v_=20200710_1"
                                                // }
                                            },
                                            b: {
                                                lineHeight: 9,
                                                align: "center",
                                                textShadowBlur: 0,
                                                shadowBlur: 0,
                                                textShadowOffsetY: -10,
                                                backgroundColor: {
                                                    image: require("@/assets/image/sjx.png")
                                                }

                                            }
                                        }
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(29, 201, 183, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(29, 201, 183, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })
            }
        },
        destroyed() {
            if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
            if (this.refreshTimer) clearInterval(this.refreshTimer)
            if (this.chart) this.chart.dispose()
            if (this.chart1) this.chart1.dispose()
            if (this.chart2) this.chart2.dispose()
            if (this.chart3) this.chart3.dispose()
        }
    }
</script>
<style lang="less" scoped>
    .pageLoading {
        background-color: #1330b8;
        color: #FFFFFF;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }

    .page {
        background-color: #1330b8;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #chart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .header {
        width: 100%;
        position: relative;

        .bj {
            width: 100%;
            vertical-align: top;
        }

        .container {
            // border: 1px solid red;
            box-sizing: border-box;
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;

            .left {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;
                padding-left: .4rem;

                .icon_tq {
                    font-size: .52rem;
                    margin-right: .2rem;
                }

                .des {
                    text-align: left;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }


            }

            .middle {
                flex: auto;
                // border: 1px solid red;
                box-sizing: border-box;
                position: relative;

                .t {
                    position: absolute;
                    left: 0.8rem;
                    top: .1rem;
                    font-size: .6rem;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                .t1 {
                    position: absolute;
                    bottom: .1rem;
                    left: 1.75rem;
                    font-size: .14rem;
                    font-weight: 400;
                    letter-spacing: 0.014rem;
                    color: #FFFFFF;
                }
            }

            .right {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;
                padding-right: 0.4rem;

                .des {
                    width: 2.88rem;
                    text-align: right;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }



            }


        }
    }

    .body {
        flex: auto;
        position: relative;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        padding: .24rem .4rem .4rem .4rem;
        justify-content: space-between;
        width: 100%;
        // border: 1px solid red;

        /deep/ .dv-scroll-board .header .header-item {
            font-size: .14rem;
            font-weight: 800;
        }

        /deep/ .dv-scroll-board .rows .row-item {
            font-weight: 400;
            font-size: .14rem;
        }

        .leftContainer {
            flex-shrink: 0;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            flex-direction: column;
            // border: 1px solid red;
            width: 4.45rem;
            justify-content: space-between;

            .top {
                display: flex;
                flex-direction: column;
                position: relative;

                .jtmax {
                    position: absolute;
                    top: 0.7rem;
                    right: 0.14rem;
                    width: .12rem;
                }


                .liContainer {
                    .li {
                        font-size: .14rem;
                        color: #FFFFFF;
                        width: 100%;
                        padding: .1rem .2rem;
                        box-sizing: border-box;
                        height: .7rem;
                        background: rgba(29, 201, 183, 0.5);
                        display: flex;
                        align-items: center;

                        .item {
                            text-align: center;
                            font-weight: 400;

                            &:nth-child(1) {
                                font-weight: 600;
                                width: 1.2rem;
                            }

                            &:nth-child(2) {
                                width: 1.2rem;
                                height: .5rem;
                                background: rgba(29, 201, 183, 0.5);
                                border: 1px solid #1DC9B7;
                                box-sizing: border-box;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: .29rem;
                            }

                            &:nth-child(3) {
                                margin: 0 .02rem;

                                .jt {
                                    position: relative;
                                    width: .12rem;
                                    top: .45rem;
                                }
                            }

                            &:nth-child(4) {
                                width: 1.2rem;
                                height: .5rem;
                                background: rgba(29, 201, 183, 0.5);
                                border: 1px solid #1DC9B7;
                                box-sizing: border-box;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }


                        }



                    }

                    &:nth-child(2) {
                        position: relative;
                        width: 100%;
                        padding-right: .4rem;
                        box-sizing: border-box;

                        .li {
                            width: 100%;
                            background: rgba(30, 143, 255, 0.5);
                            margin-top: .2rem;

                            .item {
                                &:nth-child(2) {
                                    border: 1px solid #1E8FFF;
                                    background: rgba(30, 143, 255, 0.5);
                                }

                                &:nth-child(3) {
                                    margin: 0 .02rem;

                                    .jt {
                                        position: relative;
                                        width: .12rem;
                                        top: .45rem;
                                        left: 0.265rem;
                                    }
                                }

                                &:nth-child(4) {
                                    border: 1px solid #1E8FFF;
                                    background: rgba(30, 143, 255, 0.5);
                                }
                            }
                        }
                    }

                    &:nth-child(3) {
                        .li {
                            background: rgba(194, 22, 244, 0.5);
                            margin-top: .2rem;

                            .item {
                                &:nth-child(2) {
                                    border: 1px solid #C216F4;
                                    width: 2.56rem;
                                    background: rgba(194, 22, 244, 0.4);
                                }
                            }
                        }
                    }

                }
            }


            .middle {
                flex: auto;
                overflow-y: scroll;
                background: rgba(17, 44, 171, 0.8);
                // border: 1px solid red;
                box-sizing: border-box;
                flex: auto;
                margin: .2rem 0;
                padding: .1rem 0;
                padding-right: .2rem;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                // 滚动条样式
                &::-webkit-scrollbar-track-piece {
                    background-color: transparent;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    // border-radius:0 0 5px 5px;
                    border-radius: 5px;
                    background-color: hsla(220, 4%, 58%, 0.1);
                }


                .item {
                    height: .7rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid yellow;
                    box-sizing: border-box;
                    margin-top: .18rem;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    .tag {
                        box-sizing: border-box;
                        width: .36rem;
                        padding: .09rem .08rem;
                        font-size: .2rem;
                        line-height: .25rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        background: rgba(29, 201, 183, 0.7);
                        border-radius: 0px .05rem .05rem 0px;
                    }

                    .rightContainer {
                        flex: auto;
                        margin-left: .16rem;
                        overflow: hidden;
                        // border: 1px solid red;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .title {
                            font-size: .16rem;
                            font-weight: 600;
                            color: #1DC9B7;
                        }

                        .text {
                            white-space: normal;
                            width: 100%;
                            margin-top: .04rem;
                            font-size: .125rem;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }

                    &:nth-child(2) {
                        .tag {
                            background: rgba(30, 143, 255, 0.7);
                        }

                        .rightContainer {
                            .title {
                                color: rgba(59, 157, 255, 1);
                            }
                        }
                    }

                    &:nth-child(3) {
                        .tag {
                            background: rgba(214, 74, 255, 0.7)
                        }

                        .rightContainer {
                            .title {
                                color: rgba(214, 74, 255, 1)
                            }
                        }
                    }

                    &:nth-child(4) {
                        .tag {
                            background: rgba(255, 184, 34, 0.7)
                        }

                        .rightContainer {
                            .title {
                                color: rgba(255, 184, 34, 1)
                            }
                        }
                    }
                }
            }

            .cardContainer {
                height: 2.43rem;
                flex-shrink: 0;
            }

        }


        .cardContainer {
            width: 100%;
            height: 100%;
            overflow: hidden;
            padding: .2rem;
            box-sizing: border-box;
            background: rgba(17, 44, 171, 0.8);
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                padding-left: .12rem;
                font-size: .28rem;
                font-weight: 800;
                color: #FFFFFF;
                position: relative;

                &::after {
                    content: "";
                    width: .04rem;
                    height: 70%;
                    left: 0;
                    top: 20%;
                    background: #1DC9B7;
                    position: absolute;
                }
            }



        }

        .middleContainer {
            // border: 1px solid yellow;
            box-sizing: border-box;
            margin: 0 .2rem;
            flex: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;

            .chartContainer {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .cardList {
                flex-shrink: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .card {
                    width: 2.13rem;
                    position: relative;
                    height: 1.125rem;

                    img {
                        vertical-align: top;
                        width: 100%;
                    }

                    .t {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: .2rem;
                        font-weight: 400;
                        top: .02rem;
                    }

                    .v {
                        position: absolute;
                        width: 100%;
                        top: .42rem;
                        text-align: center;
                        font-size: .4rem;
                        font-weight: 800;
                        color: #1DC9B7;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        sub {
                            font-size: .2rem;
                            font-weight: 600;
                            margin-left: .2rem;
                            position: relative;
                            opacity: 0.8;
                            top: .05rem;
                        }
                    }

                    &:nth-child(2) {
                        .v {
                            color: #FFB822;
                        }


                    }

                    &:nth-child(3) {
                        .v {
                            color: #D74CFF;
                        }
                    }

                    &:nth-child(4) {
                        .v {
                            color: #ECE156;
                        }
                    }
                }
            }

            .mapContainer {
                // border: 1px solid rgb(0, 255, 42);
                box-sizing: border-box;
                flex: auto;
                width: 100%;
            }

            .content {
                flex-shrink: 0;
                // border: 1px solid red;
                box-sizing: border-box;
                width: 100%;
                height: 2.43rem;

                .cardContainer {
                    padding-bottom: .14rem;

                    .dv-scroll-board {
                        margin-top: .2rem;
                        width: 100%;
                        flex: auto;
                        // border: 1px solid yellow;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .rightContainer {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 4.45rem;
            height: 100%;

            // border: 1px solid red;
            box-sizing: border-box;

            .cardContainer {
                margin-top: .2rem;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .card1 {
                // border: 1px solid red;
                box-sizing: border-box;

                #chart {
                    flex: auto;
                    // border: 1px solid red;
                    box-sizing: border-box;
                }
            }

            .card3 {
                flex-shrink: 0;
                height: 2.43rem;
                padding-bottom: 0.14rem;
                box-sizing: border-box;

                .dv-scroll-board {
                    margin-top: .2rem;
                }
            }
        }
    }
</style>